<template>
    <section>
        <h2 class="mb-5">Elenco Pagamenti non finalizzati</h2>
        <div class="text-right mb-3">
            <b-row>
                <b-col cols="2">
                    <b-form-select v-model="selectedMonth" :options="months">
                    </b-form-select>
                </b-col>
                <b-col cols="2">
                    <b-form-select v-model="selectedYear" :options="years">
                    </b-form-select>
                </b-col>
            </b-row>
        </div>

        <vue-good-table :columns="columns" :rows="payment_list">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'action'" class="text-nowrap">
                    <b-button variant="primary" @click="$router.push('/backend/account/' + props.row.id_user)">Dettaglio cliente</b-button>
                </span>
            </template>
            <div slot="emptystate">
                Nessun pagamento presente nel mese selezionato
            </div>
        </vue-good-table>
    </section>
</template>

<script>
import { Requests } from "@/api/requests.js";
import { BFormSelect, BFormSelectOption, BRow, BCol, BButton } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
});

const moneyFormatter = new Intl.NumberFormat("it-IT", {
    style: "currency", currency: "EUR"
})

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;

export default {
    components: {
        VueGoodTable,
        BFormSelect,
        BFormSelectOption,
        BRow, 
        BCol,
        BButton
    },

    data: () => ({
        selectedMonth: currentMonth,
        months: [
            { value: 1, text: 'Gennaio' },
            { value: 2, text: 'Febbraio' },
            { value: 3, text: 'Marzo' },
            { value: 4, text: 'Aprile' },
            { value: 5, text: 'Maggio' },
            { value: 6, text: 'Giugno' },
            { value: 7, text: 'Luglio' },
            { value: 8, text: 'Agosto' },
            { value: 9, text: 'Settembre' },
            { value: 10, text: 'Ottobre' },
            { value: 11, text: 'Novembre' },
            { value: 12, text: 'Dicembre' }
        ],
        selectedYear: currentYear,
        years: Array.from({length: currentYear - 2022 + 1}, (_, i) => 2022 + i),
        payment_list: [],
        columns: [
            {
                label: "ID",
                field: "id_order",
            },
            {
                label: "Cliente",
                field: (row) => {
                    return `${row.user_name} ${row.user_surname}`;
                },
            },
            {
                label: "Società",
                field: "user_companyname",
            },
            {
                label: "Acquisto",
                field: "subscription",
            },
            {
                label: "Data",
                field: "payment_date",
                formatFn: (val) => dateFormatter.format(new Date(val))
            },
            {
                label: "Importo",
                field: "payment_amount",
                formatFn: (val) => moneyFormatter.format(val)
            },
            {
                label: "Azioni",
                field: 'action'
            }
        ],
    }),

    created() {
        this.getLostPaymentList(this.selectedMonth, this.selectedYear);
    },
    watch: {
        selectedMonth: function(val) {
            this.getLostPaymentList(this.selectedMonth, this.selectedYear);
        },
        selectedYear: function(val) {
            this.getLostPaymentList(this.selectedMonth, this.selectedYear);
        }

    },

    methods: {
        async getLostPaymentList(month, year) {
            try {
                const data= new FormData();
                data.append('month', month);
                data.append('year', year);
                const response = await Requests.getLostPaymentList(data);
                this.payment_list = response.data;
                console.log(this.payment_list);
            } catch {
                console.log(err);
            }
        },
    },
};
</script>